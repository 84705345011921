$red: #179CEE;
$brand-primary: rgb(253, 103, 105);

$red-light: rgba(253, 103, 105, .5);

$gray: rgb(82, 82, 82);
$gray-light: rgb(82, 82, 82);

$gray-dark: rgb(68, 68, 68);
$gray-muted: rgb(128, 128, 128);
$white-faded: rgb(153, 153, 153);

$pre-background: rgb(246, 246, 246);