/*
|--------------------------------------------------------------------------
| Canvas Child-Theme Styles : Frontend
|
| In this section you can define your own styles for the frontend of
| the blog. By setting styles here and leaving the core alone you
| can migrate this theme to another installation of Canvas.
|--------------------------------------------------------------------------
*/

